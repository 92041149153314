<template>
  <div class="orderwall">
    <div class="content">
      <div class="left">
        <div class="todo_box">
          <div class="title">工单处理</div>
          <div v-if="authAllMap['workOrder:list']" class="todo_ul">
            <div class="todo_li one" @click="goLinkHandle(1, todoWork.pendingOrdersNum)">
              <img class="img" :src="require('@/assets/imgs/orderwall/01.png')" />
              <div class="do_box">
                <div class="name">待接单工单</div>
                <div class="value">{{ todoWork.pendingOrdersNum }}</div>
                <i class="arrow iconfont icon-neirong"></i>
              </div>
            </div>
            <div class="todo_li two" @click="goLinkHandle(2, todoWork.toBeCompletedNum)">
              <img class="img" :src="require('@/assets/imgs/orderwall/02.png')" />
              <div class="do_box">
                <div class="name">待完工工单</div>
                <div class="value">{{ todoWork.toBeCompletedNum }}</div>
                <i class="arrow iconfont icon-neirong"></i>
              </div>
            </div>
            <div class="todo_li three" @click="goLinkHandle(3, todoWork.pendingCloseNum)">
              <img class="img" :src="require('@/assets/imgs/orderwall/04.png')" />
              <div class="do_box">
                <div class="name">待关单工单</div>
                <div class="value">{{ todoWork.pendingCloseNum }}</div>
                <i class="arrow iconfont icon-neirong"></i>
              </div>
            </div>
          </div>
          <div v-else class="noData">
            <img :src="require('@/assets/imgs/noData.png')" />
            <div class="nodata_text">暂无数据</div>
          </div>
        </div>
        <div class="synergy_box">
          <div class="title">待办事项</div>
          <Synergy />
        </div>
      </div>
      <div class="right">
        <div class="title">工单统计</div>
        <div class="statistics_ul">
          <div class="statistics_li" v-for="(item, index) in workOrderList" :key="index">
            <div class="titleText item">{{ item.titleText }}</div>
            <div class="orderNum item">
              <div class="num">{{ item.orderNum }}</div>
              <div class="label">{{ item.orderNumText }}</div>
            </div>
            <div class="bottom item">
              <div class="label">{{ item.bottomText }}</div>
              <div class="num">{{ item.bottomNum }}</div>
            </div>
            <div v-if="item.rateNum || item.rateNum === 0" class="rate_box item">
              <div class="label">{{ item.rateText }}</div>
              <div :class="['num', +item.rateNum.replace('%', '') > 0 ? 'up' : +item.rateNum.replace('%', '') < 0 ? 'down' : '']">{{ item.rateNum }}</div>
              <i v-if="item.iconFlag && +item.rateNum !== 0"
                :class="['iconfont', +item.rateNum.replace('%', '') > 0 ? 'icon-shangsheng up' : 'icon-xiajiang down']"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="charts_content">
      <div class="chart left">
        <div class="title">运营统计</div>
        <Operational />
      </div>
      <div class="chart right">
        <div class="title">工单评价</div>
        <Evaluation />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { todoWork, workOrderStatistics } from '@/api/orderwall.js';
import { WORKORDERLIST, DATE_KEY_LIST } from './benchSetting.js';
import Evaluation from './Components/evaluation.vue';
import Operational from './Components/operational.vue';
import Synergy from './Components/synergy.vue';

export default {

  components: {
    Evaluation,
    Operational,
    Synergy,
  },
  computed: {
    ...mapGetters(['authAllMap']),
  },
  data () {
    return {
      DATE_KEY_LIST,
      workorderlistSetting: JSON.parse(JSON.stringify(WORKORDERLIST)),
      todoWork: {},
      evaluat: {},
      workOrder: '',
      workOrderList: [],
    };
  },
  mounted () {
    this.getTodoWork();
    this.getWorkOrder();
  },
  methods: {
    getTodoWork () {
      todoWork().then(({ body }) => {
        this.todoWork = body;
      });
    },

    getWorkOrder () {
      workOrderStatistics().then(({ body }) => {
        this.workOrder = body;
        if (!body || typeof body !== 'object') {
          this.workOrderList = [];
          // 权限
          return;
        }
        this.workOrderList = this.workorderlistSetting.map(item => {
          Object.keys(item).forEach(k => {
            if (this.DATE_KEY_LIST.includes(item[k])) {
              item[k] = body[item[k]] || '0';
            }
          });
          return item;
        });
      });
    },
    goLinkHandle (type, num) {
      if (!Number(num)) return;
      switch (type) {
      case 1:
        this.$router.push({
          name: 'ORDER_INDEX',
          params: {
            independentStatusMsg: [{
              value: 'PENDING_ORDERS',
              label: '待接单',
            }],
          },
        });
        break;
      case 2:
        this.$router.push({
          name: 'ORDER_INDEX',
          params: {
            independentStatusMsg: [{
              value: 'TO_BE_COMPLETED',
              label: '待完工',
            }],
          },
        });
        break;
      case 3:
        this.$router.push({
          name: 'ORDER_INDEX',
          params: {
            independentStatusMsg: [{
              value: 'TO_BE_COMPLETED',
              label: '待完工',
            }],
            statusMsg: [{
              value: 'SIGNED',
              label: '已签字',
            }],
          },
        });
        break;
      default:
        break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.orderwall {
  min-height: 500px;
  padding-bottom: 25px;

  .content {
    display: flex;
    height: 462px;
    margin-bottom: 8px;

    .todo_box {
      height: 170px;
      margin-bottom: 8px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(36, 40, 50, 0.02);
      border-radius: 4px;
      overflow: hidden;

      .todo_ul {
        margin: 24px 26px;
        display: flex;
        height: 82px;
        overflow: hidden;
        justify-content: space-between;

        .todo_li {
          cursor: pointer;
          position: relative;
          flex: 1;
          // padding: 10px 0 10px 16px;
          display: flex;
          border-radius: 4px;
          box-sizing: border-box;
          overflow: hidden;

          &+.todo_li{
            margin-left: 8px;
          }

          .img {
            display: block;
            width: 62px;
            height: 62px;
            flex-shrink: 0;
            margin: 10px 12px 10px 16px;
          }

          .do_box {
            flex: 1;
            position: relative;
            padding-top: 14px;
            background: url('../../assets/imgs/orderwall/logo.png') no-repeat;
            background-position: right bottom;
            background-size: 90px;

            .name {
              font-size: 14px;
              font-weight: 600;
              color: rgba(36, 40, 50, 0.7);
              line-height: 14px;
            }

            .value {
              font-size: 32px;
              font-weight: 600;
              color: #242832;
              line-height: 32px;
              margin-top: 7px;
            }

            .arrow {
              position: absolute;
              right: 10px;
              top: 14px;
              font-size: 16px;
              font-weight: 400;
              color: rgba(36, 40, 50, 0.6);
              line-height: 16px;
            }
          }

          &.one {
            background: #EAFFE0;
          }

          &.two {
            background: #E9F4FF;
          }

          &.three {
            background: #FFECD3;
          }

           &.four {
            background: #FFECEC;
          }

        }
      }

      .noData {
        height: 120px;

        img {
          display: block;
          margin: auto;
          height: 92px;
        }

        .nodata_text {
          font-size: 16px;
          font-weight: 400;
          color: #5195EB;
          line-height: 16px;
          text-align: center;
        }
      }
    }

    .synergy_box {
      width: 100%;
      height: 285px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(36, 40, 50, 0.02);
      border-radius: 4px;
      overflow: hidden;

      .title {
        position: absolute;
      }
    }

    .right {
      .title {
        position: absolute;
      }
    }

    .statistics_ul {
      margin: 64px 28px;
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;

      .statistics_li {
        flex: 0 0 49%;
        height: 122px;
        margin-bottom: 8px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 0px rgba(36, 40, 50, 0.08);
        border-radius: 4px;
        border: 1px solid #F0F0F0;
        overflow: hidden;
        box-sizing: border-box;
        padding: 12px 0 12px 18px;

        .titleText {
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          margin-bottom: 8px;
          color: rgba(36, 40, 50, 0.7);
        }

        .orderNum {
          margin-bottom: 8px;

          .num {
            display: inline-block;
            vertical-align: baseline;
            font-size: 32px;
            font-weight: 500;
            color: rgba(36, 40, 50, 0.9);
            line-height: 28px;
            margin-right: 12px;
          }

          .label {
            display: inline-block;
            vertical-align: baseline;
            font-size: 16px;
            font-weight: 400;
            color: rgba(36, 40, 50, 0.79);
            line-height: 16px;
          }
        }

        .bottom {
          margin-bottom: 8px;

          .label {
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
            font-weight: 400;
            color: rgba(36, 40, 50, 0.4);
            line-height: 14px;
            margin-right: 6px;
          }

          .num {
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
            font-weight: 400;
            color: #242832;
            line-height: 14px;
          }
        }

        .rate_box {
          margin-bottom: 8px;

          .label {
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
            font-weight: 400;
            color: rgba(36, 40, 50, 0.4);
            line-height: 14px;
            margin-right: 6px;
          }

          .num {
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
            font-weight: 400;
            color: #242832;
            line-height: 14px;
            margin-right: 4px;

            &.up {
              color: #FC4E4E;
            }

            &.down {
              color: #6ac144;
            }
          }

          .iconfont {
            &.up {
              color: #FC4E4E;
            }

            &.down {
              color: #6ac144;
            }
          }
        }

        &>.item:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .charts_content {
    display: flex;
    height: calc(100vh - 600px);
    min-height: 350px;

    .charts {
      height: 240px;
    }

    .left {
      background: #fff;
    }

    .title {
      position: absolute;
    }
  }

  .left {
    margin-right: 8px;
    flex: 2;
    border-radius: 4px;
  }

  .right {
    flex: 1;
    background: #fff;
    overflow: hidden;
    border-radius: 4px;
  }

  .title {
    padding-top: 20px;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    color: rgba(36, 40, 50, 0.6);
    line-height: 16px;
    padding-left: 10px;
  }
}
</style>
